@import "Styles/_variables.scss";
@import "Styles/_mixins.scss";
@import "Styles/_fonts.scss";

footer {
  padding: $gap-100 4.5rem 3rem;
  background-color: $baipro-light-grey;
  .logo {
    height: 3rem;
    margin-bottom: 1.25rem;
  }
  .main {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3.25rem;
    .content {
      display: flex;
      justify-content: space-between;
      width: 100%;
      @include mobile {
        flex-direction: column-reverse;
        gap: 0;
      }
      &-links {
        display: flex;
        gap: 3.25rem;
        @include mobile {
          flex-direction: column;
          gap: 0;
        }
        .link {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}
