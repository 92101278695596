// globat charset
@charset "UTF-8";

@import "Styles/_fonts.scss";
@import "Styles/_variables.scss";
@import "Styles/_mixins.scss";

.App {
  height: 100%;
  width: 100%;
  display: inline-block;
  font-family: "SFProDisplay-Regular";
  background-color: white;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

// SCROLLBAR

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: $baipro-blue $baipro-blue;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  margin: 10px;
}

*::-webkit-scrollbar-track {
  margin: 0 6px;
  border-radius: 0 0 2rem 0;
  background: $bai-light-grey;
}

*::-webkit-scrollbar-thumb {
  background-color: $baipro-blue;
  border-radius: 6px;
}
