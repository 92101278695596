@mixin font-face($name, $fileName) {
    $fileName: '../Assets/fonts/' + $fileName;

    @font-face {
        font-family: quote($name);
        src: url(quote($fileName + ".woff2")) format("woff2"),
        url(quote($fileName + ".woff")) format("woff");
        font-weight: normal;
        font-style: normal;
    }
}


@include font-face('SFProDisplay-Regular', 'SFProDisplay-Regular');
@include font-face('SFProDisplay-Medium', 'SFProDisplay-Medium');
@include font-face('SFProDisplay-RegularItalic', 'SFProDisplay-RegularItalic');
@include font-face('Satoshi-VariableItalic', 'Satoshi-VariableItalic' );
@include font-face('Satoshi-Variable','Satoshi-Variable');
@include font-face('Satoshi-Regular','Satoshi-Regular');
@include font-face('Satoshi-MediumItalic','Satoshi-MediumItalic');
@include font-face('Satoshi-Medium','Satoshi-Medium');
@include font-face('Satoshi-LightItalic','Satoshi-LightItalic');
@include font-face('Satoshi-Light','Satoshi-Light' );
@include font-face('Satoshi-Italic','Satoshi-Italic');
@include font-face('Satoshi-BoldItalic', 'Satoshi-BoldItalic');
@include font-face('Satoshi-Bold','Satoshi-Bold');
@include font-face('Satoshi-BlackItalic','Satoshi-BlackItalic');
@include font-face('Satoshi-Black','Satoshi-Black');
@include font-face('NeueHaasDisplay-Medium', 'NeueHaasDisplay-Medium');
@include font-face('NeueHaasDisplay-Bold', 'NeueHaasDisplay-Bold');
@include font-face('NeueHaasDisplay-Roman', 'NeueHaasDisplay-Roman');
@include font-face('NeueHaasDisplay-Light', 'NeueHaasDisplay-Light');