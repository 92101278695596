@import "Styles/_variables.scss";
@import "Styles/_mixins.scss";

.scrollToTop{
    position: fixed;
    top: 88%;
    right: 2%;
    border-radius: 2rem;
    background-color: #04153d;
    width: 3rem;
    height: 3rem;
    z-index: 9;
    @include mobile{
        top: 90%;
        right: 3%;
    }

    .icon{
        width: 43%;
        fill: white;
    }
}

.visibilityNone{
    visibility: hidden
}

.visibilityYes{
    visibility: visible;
}