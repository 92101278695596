@mixin mobile {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: 600px) and (max-width: 899px) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: 900px) and (max-width: 1199px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1200px) and (max-width: 1599px) {
    @content;
  }
}

@mixin big-desktop {
  @media (min-width: 1600px) {
    @content;
  }
}

@mixin primary-button(
  $fontSize: 1rem,
  $padding: 0.5rem 2rem,
  $backgroundColor: $baipro-blue
) {
  background-color: $backgroundColor;
  color: $baipro-white;
  border: none;
  width: fit-content;
  padding: $padding;
  font-size: $fontSize;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: darken($baipro-blue, 10%);
  }
  &:disabled {
    background-color: $baipro-disabled-grey;
    cursor: default;
  }
}
