@import "Styles/_mixins.scss";
@import "Styles/_variables.scss";

.divHead {
  overflow: hidden;
  z-index: 999999;
  position: absolute;
}

.header {
  &_container {
    height: 5.5rem;
    width: 100%;
    padding: 0 5rem;
    font-size: 1rem;
    transition: all 0.5s linear;
    position: fixed;
    background-image: $baipro-header-gradient;
    li {
      a {
        color: $baipro-white;
      }
    }

    @include mobile {
      display: flex;
      height: 5rem;
      padding: 0 4rem;
      flex-direction: column;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.425);
    }
  }
  &_container--white {
    background-color: $baipro-white;
    background-image: none;
    li {
      a {
        color: $baipro-blue;
      }
    }
  }
  &_container--trasparent {
    background-color: #0000005c;
    li {
      a {
        color: $baipro-white;
      }
    }
    @include mobile {
      background-color: rgba(255, 255, 255, 0.425);
    }
  }
}
