.loading-container {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin: 10rem 0;
    width: auto;
    height: 100vw;
}

.loading-image {
    position: fixed;
    width: 10%;
    object-fit: cover;
    opacity: 50%;
    animation: loading 1s infinite;
    margin-top: 9rem;

}

@keyframes loading {
    0% {
        scale: 0;
    }

    50% {
        scale: 1.5;
    }

    100% {
        scale: 2;
    }
}