$baipro-jobs-background: #01818c;
$baipro-btn-background: #09add2;

$bai-light-grey: #f5f5f5;
$bai-dark-blue: #051c2b;
$bai-sky-blue: #01aace;
$bai-pillars-subtitle: #0c2b3f;
$bai-grey: #a2a2a2;
$bai-dark-blue-btn: #04153d;
$bai-sky-blue: #01aace;
$bai-pillars-mark: #01aace;

$bai-font-title: "NeueHaasDisplay-Medium";
$bai-font-text: "Satoshi-Light";

// NUEVO SISTEMA DE DISEÑO DE BAIPRO A IMPLEMENTAR EN EL PROYECTO

// COLORS

$baipro-sky-blue: #03abd2;
$baipro-blue: #101938;
$baipro-white: #ffffff;
$baipro-light-grey: #f5f5f5;
$baipro-disabled-grey: #bebbba;
$baipro-purple: #857dbb;
$baipro-brown: #a86d28;
$baipro-yellow: #f0cd12;
$baipro-orange: #f6914d;
$baipro-green: #8bc63e;
$baipro-background-grey: #d6d9dc;

$baipro-black: #000;
$baipro-red: rgba(255, 35, 35, 0.953);
$baipro-header-gradient: linear-gradient(
  to top,
  rgba(0, 0, 0, 0),
  rgba(0, 0, 0, 0.8)
);

// UTILS
$gap-100: 6.25rem;

// FONTS

$baipro-font-header: "Satoshi-Medium";
$baipro-font-footer: "Satoshi-Regular";
$baipro-font-page-header: "NeueHaasDisplay-Bold";
$baipro-font-page-introduction: "SFProDisplay-Regular";
$baipro-font-page-introduction-mark: "SFProDisplay-Medium";
$baipro-font-section-title: "Satoshi-Bold";
$baipro-font-section-subtitle: "Satoshi-Bold";
$baipro-font-buttons: "Satoshi-Medium";
$baipro-font-text: "Satoshi-Regular";
$baipro-font-testimonials: "NeueHaasDisplay-Medium";
