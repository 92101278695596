@import 'Styles/_mixins.scss';
@import 'Styles/_variables.scss';

.navBaipro {
    font-family: "Satoshi";
    display: flex;
    justify-content: space-between;



    &-list {
        display: flex;
        list-style-type: none;
        align-items: center;
        justify-content: space-around;



        @include tablet-portrait {
            justify-content: space-around;
        }

        li {
            font-size: 1rem;
            padding: .5rem;
            cursor: pointer;


            @include tablet-portrait {
                padding: 0.7rem;
            }

            a {
                color: $baipro-white;
                padding: 1rem;
                text-decoration: none;


                @include tablet-portrait {
                    padding: 0.3rem;
                }
            }
        }

        &_btnLogin {
            background-color: $baipro-btn-background;
            font-family: "Satoshi";
            color: $baipro-white;
            border-style: none;
            width: 9.5rem;
            height: 3.4rem;
            opacity: 100%;
            text-align: center;
            font-size: 1rem;
            font-weight: 500;
            cursor: pointer;



            @include tablet-portrait {
                width: 7rem;
                height: 3rem;
            }
        }

        &_logo {
            max-width: 3rem;
            min-width: 3rem;
            grid-area: brand;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width: max-content;

            }
        }
    }


    &-icon {
        width: max-content;
        display: flex;
        align-items: center;
        gap: 1.5rem;

        &_languages {
            width: auto;

            button {
                fill: $baipro-black;
                background-color: transparent;
                cursor: pointer;
                display: flex;
                border: none;
            }
        }

        &_search {
            width: auto;

            button {
                fill: $baipro-black;
                background-color: transparent;
                cursor: pointer;
                display: flex;
                border: none;
            }
        }
    }

    &_hamburger {
        margin-top: 0.5rem;
        display: flex;
        align-items: center;
        width: 2.5rem;
        background-color: transparent;
        border: none;
    }
}

.NavMobile {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-left {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 12rem;
        align-items: center;

        &__Logo {
            width: 2.3rem;
        }

        &__logoText {
            width: 9rem;
        }
    }

    &-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 6rem;
        margin-bottom: 0.5rem;

        &__btnLenguage {
            margin-top: 1rem;
            border: none;
            background:transparent;
        }   
    }

    &-list {
        display: flex;
        flex-direction: column;
        padding: 1.2rem;
        background: #FFF;
        width: 18rem;
        height: auto;
        border-radius: 20px 0px 20px 20px;

        li {
            padding: .5rem;
            cursor: pointer;
            list-style: none;
            color: #024351;
            font-family: Satoshi;
            font-size: 2rem;
            line-height: normal;
            display: flex;
        }

        a {
            color: $baipro-blue;
            padding: 1rem;
            text-decoration: none;

        }
    }
}

div#simple-popper.MuiPopup-root.Mui-open {
    left: 70% !important;
    top: 8% !important;
    transform: translate(-50%, 0) !important; /* Centra horizontalmente */
    z-index: 999999;
}

;


.btnLoginMobile {
    color: $baipro-white;
    font-family: Satoshi;
    width: 9rem;
    height: 5rem;
    font-size: 2rem;
    line-height: normal;
    background-color: $bai-sky-blue;

}

.menu-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fondo oscuro con transparencia */
    z-index: 1000;
    /* Asegura que esté por encima del contenido */
}

.visibilityNone{
    visibility: hidden
}

.visibilityYes{
    visibility: visible;
}