@import "Styles/_mixins.scss";

.socials {
  display: flex;
  gap: 1.25rem;
  align-items: center;
  height: 20px;
  @include mobile {
    height: 12px;
    align-items: flex-end;
    gap: 0.5rem;
  }
  a {
    height: inherit;
    width: fit-content;
  }
  &_icons {
    display: flex;
    height: inherit;
    fill: inherit;
    width: auto;
    &:hover {
      scale: 1.1;
    }
  }
}
